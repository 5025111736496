import React from 'react';

import { useAuth0 } from '../../contexts/Auth0Context';

const PrivateRoute = ({ component: Component, location, ...props }) => {
  const { isAuth0Ready, isAuthenticated, loginWithRedirect } = useAuth0();

  if (!isAuth0Ready) {
    return null;
  }

  if (!isAuthenticated) {
    loginWithRedirect({ targetUrl: location.pathname });
    return null;
  }

  return <Component {...props} />;
};

export default PrivateRoute;
