import React from 'react';
import { Router } from '@reach/router';

import PrivateRoute from '../../components/PrivateRoute';

import MainPage from './pages/MainPage';

const MyPageApp = () => {
  return (
    <Router basepath="/mypage" className="root">
      <PrivateRoute path="/" component={MainPage} />
    </Router>
  );
};

export default MyPageApp;
