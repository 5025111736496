import React, { useState, useMemo, useEffect, useCallback } from 'react';

import { useBackendApi } from '../../contexts/BackendApiContext';
import { EMAIL_SETTINGS_DELIVERY } from '../../contexts/BackendApiContext/EmailSettingsContext';

import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import CheckboxWithLabelDefault from '../../molecules/common/CheckboxWithLabelDefault';

import ButtonPrimary from '../../atoms/ButtonPrimary';

import * as styles from './index.module.css';

const EmailSettingsForm = () => {
  const {
    emailSettings,
    isEmailSettingsReady,
    isRequireVerifiedSocilaLoginEmail,
    updateEmailSettings,
    isUpdateEmailSettingsLoading,
  } = useBackendApi();

  const [formEmailSettings, setFormEmailSettings] = useState(undefined);
  const [isChanged, setIsChanged] = useState(false);
  const [isRequested, setIsRequested] = useState(false);

  const isUpdated = useMemo(() => {
    return !isUpdateEmailSettingsLoading && !isChanged && isRequested;
  }, [isUpdateEmailSettingsLoading, isChanged, isRequested]);

  const onDeliveryInputChange = useCallback(event => {
    const { checked } = event.target;

    setFormEmailSettings(formEmailSettings => ({
      ...formEmailSettings,
      delivery: checked
        ? EMAIL_SETTINGS_DELIVERY.ALLOW
        : EMAIL_SETTINGS_DELIVERY.DENY,
    }));

    setIsChanged(true);
    setIsRequested(false);
  }, []);

  const onSubmit = useCallback(
    event => {
      if (!isEmailSettingsReady || isUpdateEmailSettingsLoading) {
        event.preventDefault();
        return;
      }

      event.preventDefault();

      updateEmailSettings({
        variables: { ...formEmailSettings },
      });

      setIsChanged(false);
      setIsRequested(true);
    },
    [
      isEmailSettingsReady,
      updateEmailSettings,
      isUpdateEmailSettingsLoading,
      formEmailSettings,
    ]
  );

  useEffect(() => {
    if (!emailSettings) {
      return;
    }

    setFormEmailSettings(emailSettings);
  }, [emailSettings]);

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className={styles['inputArea']}>
          <div>
            <InlineBlockWrapper>
              <CheckboxWithLabelDefault
                label="新着記事のお知らせを受け取る"
                checked={
                  formEmailSettings
                    ? formEmailSettings.delivery ===
                      EMAIL_SETTINGS_DELIVERY.ALLOW
                    : false
                }
                onChange={onDeliveryInputChange}
                disabled={isRequireVerifiedSocilaLoginEmail}
              />
            </InlineBlockWrapper>
          </div>
        </div>
        <div>
          <InlineBlockWrapper className={styles['buttonWrapper']}>
            <ButtonPrimary
              type="submit"
              disabled={!isChanged || isUpdateEmailSettingsLoading}
            >
              {!isUpdated ? 'メール配信設定を保存' : '保存しました'}
            </ButtonPrimary>
          </InlineBlockWrapper>
        </div>
      </form>
    </div>
  );
};

export default EmailSettingsForm;
