import React, { useMemo } from 'react';

import * as styles from './index.module.css';

const FormLabel = ({ label, required }) => {
  const labelClass = useMemo(() => {
    return [styles['label'], required && styles['required']]
      .filter(Boolean)
      .join(' ');
  }, [required]);

  return (
    <div className={styles['root']}>
      <span className={labelClass}>{label}</span>
    </div>
  );
};

export default FormLabel;
