import React from 'react';

import * as styles from './index.module.css';

const RadioButtonDefault = props => {
  return (
    <div className={styles['root']}>
      <input className={styles['input']} type="radio" {...props} />
      <span className={styles['radio']}></span>
    </div>
  );
};

export default RadioButtonDefault;
