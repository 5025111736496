import React, { useMemo } from 'react';

import * as styles from './index.module.css';

const TextFieldDefault = ({ block, ...props }) => {
  const rootClass = useMemo(() => {
    return [styles['root'], block && styles['block']].filter(Boolean).join(' ');
  }, [block]);

  return (
    <div className={rootClass}>
      <input className={styles['input']} {...props} />
    </div>
  );
};

export default TextFieldDefault;
