import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import { useBackendApi } from '../../contexts/BackendApiContext';
import { AVAILABLE_CLAIMS } from '../../contexts/BackendApiContext/UserContext';

import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import TextFieldWithLabelDefault from '../../molecules/common/TextFieldWithLabelDefault';
import RadioButtonWithLabelDefault from '../../molecules/common/RadioButtonWithLabelDefault';

import FormLabel from '../../atoms/FormLabel';
import ButtonPrimary from '../../atoms/ButtonPrimary';

import * as styles from './index.module.css';

const ProfileForm = () => {
  const {
    user,
    isUserReady,
    validateClaims,
    patchUserClaims,
    isPatchUserClaimsLoading,
  } = useBackendApi();

  const formRef = useRef();

  const [formProfile, setFormProfile] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isRequested, setIsRequested] = useState(false);

  const isUpdated = useMemo(() => {
    return !isPatchUserClaimsLoading && !isChanged && isRequested;
  }, [isPatchUserClaimsLoading, isChanged, isRequested]);

  const validate = useCallback(
    profile => {
      if (!formRef.current) {
        return;
      }

      const isFormValid = formRef.current.checkValidity();

      setIsValid(isFormValid && validateClaims(profile));
    },
    [validateClaims, formRef]
  );

  const onInputChange = useCallback(event => {
    const { name, value } = event.target;

    setFormProfile(formProfile => ({
      ...formProfile,
      [name]: value,
    }));

    setIsChanged(true);
    setIsRequested(false);
  }, []);

  const onSubmit = useCallback(
    event => {
      if (!isUserReady || isPatchUserClaimsLoading) {
        event.preventDefault();
        return;
      }

      if (isValid) {
        event.preventDefault();

        const birthdateObj = new Date(formProfile.birthdate);

        const normalizedProfile = {
          ...formProfile,
          birthdate: birthdateObj.toISOString().slice(0, 10),
        };

        patchUserClaims({
          variables: { ...normalizedProfile },
        });

        setIsChanged(false);
        setIsRequested(true);
      }
    },
    [
      isUserReady,
      patchUserClaims,
      isPatchUserClaimsLoading,
      isValid,
      formProfile,
    ]
  );

  useEffect(() => {
    if (!isChanged) {
      return;
    }

    validate(formProfile);
  }, [formProfile, isChanged, validate]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!user.user_metadata) {
      setFormProfile({});
      return;
    }

    const formProfile = AVAILABLE_CLAIMS.reduce((acc, current) => {
      acc[current] = user.user_metadata[current];
      return acc;
    }, {});

    setFormProfile(formProfile);
  }, [user]);

  return (
    <div>
      <form ref={formRef} onSubmit={onSubmit}>
        <div className={styles['inputArea']}>
          <div className={styles['nameArea']}>
            <InlineBlockWrapper className={styles['nameTextFieldWrapper']}>
              <TextFieldWithLabelDefault
                name="family_name"
                label="姓"
                block
                required
                value={formProfile.family_name || ''}
                onChange={onInputChange}
              />
            </InlineBlockWrapper>
            <InlineBlockWrapper className={styles['nameTextFieldWrapper']}>
              <TextFieldWithLabelDefault
                name="given_name"
                label="名"
                block
                required
                value={formProfile.given_name || ''}
                onChange={onInputChange}
              />
            </InlineBlockWrapper>
          </div>

          <div className={styles['nameKanaArea']}>
            <InlineBlockWrapper className={styles['nameTextFieldWrapper']}>
              <TextFieldWithLabelDefault
                name="family_name_kana"
                label="姓（カナ）"
                block
                required
                value={formProfile.family_name_kana || ''}
                onChange={onInputChange}
              />
            </InlineBlockWrapper>
            <InlineBlockWrapper className={styles['nameTextFieldWrapper']}>
              <TextFieldWithLabelDefault
                name="given_name_kana"
                label="名（カナ）"
                block
                required
                value={formProfile.given_name_kana || ''}
                onChange={onInputChange}
              />
            </InlineBlockWrapper>
          </div>

          <div className={styles['birthdateArea']}>
            <InlineBlockWrapper>
              <TextFieldWithLabelDefault
                name="birthdate"
                label="生年月日"
                type="date"
                required
                value={formProfile.birthdate || ''}
                placeholder="YYYY-MM-DD"
                onChange={onInputChange}
              />
            </InlineBlockWrapper>
          </div>

          <div>
            <InlineBlockWrapper className={styles['genderLabelWrapper']}>
              <FormLabel label="性別" required />
            </InlineBlockWrapper>
            <div className={styles['genderRadioGroup']}>
              <InlineBlockWrapper className={styles['genderRadioWrapper']}>
                <RadioButtonWithLabelDefault
                  name="gender"
                  label="男性"
                  defaultValue="male"
                  required
                  checked={formProfile.gender === 'male'}
                  onChange={onInputChange}
                />
              </InlineBlockWrapper>
              <InlineBlockWrapper className={styles['genderRadioWrapper']}>
                <RadioButtonWithLabelDefault
                  name="gender"
                  label="女性"
                  defaultValue="female"
                  required
                  checked={formProfile.gender === 'female'}
                  onChange={onInputChange}
                />
              </InlineBlockWrapper>
              <InlineBlockWrapper className={styles['genderRadioWrapper']}>
                <RadioButtonWithLabelDefault
                  name="gender"
                  label="その他"
                  defaultValue="other"
                  required
                  checked={formProfile.gender === 'other'}
                  onChange={onInputChange}
                />
              </InlineBlockWrapper>
            </div>
          </div>
        </div>

        <div>
          <InlineBlockWrapper className={styles['buttonWrapper']}>
            <ButtonPrimary
              type="submit"
              disabled={!isChanged || !isValid || isPatchUserClaimsLoading}
            >
              {!isUpdated ? 'プロフィールを保存' : '保存しました'}
            </ButtonPrimary>
          </InlineBlockWrapper>
        </div>
      </form>
    </div>
  );
};

export default ProfileForm;
