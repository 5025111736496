import React, { useMemo } from 'react';

import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import RadioButtonDefault from '../../../atoms/RadioButtonDefault';

import * as styles from './index.module.css';

const RadioButtonWithLabelDefault = ({ label, block, ...props }) => {
  const rootClass = useMemo(() => {
    return [styles['root'], block && styles['block']].filter(Boolean).join(' ');
  }, [block]);

  return (
    <div className={rootClass}>
      <label className={styles['wrapper']}>
        <InlineBlockWrapper>
          <RadioButtonDefault {...props} />
        </InlineBlockWrapper>
        <span className={styles['label']}>{label}</span>
      </label>
    </div>
  );
};

export default RadioButtonWithLabelDefault;
