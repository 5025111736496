import React, { useMemo } from 'react';

import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import TextFieldDefault from '../../../atoms/TextFieldDefault';
import FormLabel from '../../../atoms/FormLabel';

import * as styles from './index.module.css';

const TextFieldWithLabelDefault = ({ label, block, horizontal, ...props }) => {
  const rootClass = useMemo(() => {
    return [styles['root'], block && styles['block']].filter(Boolean).join(' ');
  }, [block]);

  const innerClass = useMemo(() => {
    return [styles['inner'], horizontal && styles['horizontal']]
      .filter(Boolean)
      .join(' ');
  }, [horizontal]);

  return (
    <div className={rootClass}>
      <label className={innerClass} htmlFor={label}>
        <InlineBlockWrapper className={styles['labelWrapper']}>
          <FormLabel label={label} required={props.required} />
        </InlineBlockWrapper>
        <div className={styles['textFieldWrapper']}>
          <TextFieldDefault block={block} id={label} {...props} />
        </div>
      </label>
    </div>
  );
};

export default TextFieldWithLabelDefault;
