import React, { Fragment, useCallback } from 'react';
import { Location } from '@reach/router';

import { useAuth0 } from '../../../../contexts/Auth0Context';
import { useBackendApi } from '../../.././../contexts/BackendApiContext';

import useInitialSettings from '../../../../hooks/useInitialSettings';

import SEO from '../../../../components/SEO';
import PageDefault from '../../../../components/PageDefault';
import ContentWrapper from '../../../../components/ContentWrapper';
import ContainerDefault from '../../../../components/ContainerDefault';
import InlineBlockWrapper from '../../../../components/InlineBlockWrapper';

import SiteHeader from '../../../../organisms/SiteHeader';
import SiteFooter from '../../../../organisms/SiteFooter';
import GlobalNavigation from '../../../../organisms/GlobalNavigation';
import ProfileForm from '../../../../organisms/ProfileForm';
import EmailSettingsForm from '../../../../organisms/EmailSettingsForm';

import MainHeading from '../../../../molecules/common/MainHeading';
import ContentArea from '../../../../molecules/common/ContentArea';

import LinkButtonPrimaryLarge from '../../../../atoms/LinkButtonPrimaryLarge';
import ButtonDefault from '../../../../atoms/ButtonDefault';

import * as styles from './index.module.css';

const MainPage = ({ location }) => {
  const { logout } = useAuth0();
  const {
    isRequireUpdateClaims,
    isRequireVerifiedSocilaLoginEmail,
  } = useBackendApi();

  const onLoguoutButtonClick = useCallback(() => {
    logout();
  }, [logout]);

  useInitialSettings({ location });

  return (
    <Fragment>
      <SEO title="マイページ" path={location.href} />
      <SiteHeader />

      <PageDefault>
        <ContentWrapper>
          <main className={styles['main']}>
            <section className={styles['section']}>
              <ContainerDefault>
                <div className={styles['headingWrapper']}>
                  <InlineBlockWrapper>
                    <MainHeading heading="マイページ" />
                  </InlineBlockWrapper>
                </div>

                <section className={styles['profileSection']}>
                  <ContentArea>
                    <div className={styles['contentAreaHeadingWrapper']}>
                      <h2 className={styles['contentAreaHeading']}>
                        プロフィール
                      </h2>
                    </div>

                    {isRequireUpdateClaims && (
                      <div className={styles['messageWrapperInfo']}>
                        <p className={styles['message']}>
                          プロフィールの入力をお願いいたします。
                        </p>
                        <p className={styles['message']}>
                          お知らせメールや今後の会員様向けコンテンツの拡充に利用いたします。
                        </p>
                      </div>
                    )}

                    <div>
                      <ProfileForm />
                    </div>
                  </ContentArea>
                </section>

                <section className={styles['emailSettingsSection']}>
                  <ContentArea>
                    <div className={styles['contentAreaHeadingWrapper']}>
                      <h2 className={styles['contentAreaHeading']}>
                        メール配信設定
                      </h2>
                    </div>

                    {isRequireVerifiedSocilaLoginEmail && (
                      <div className={styles['messageWrapperInfo']}>
                        <p className={styles['message']}>
                          ログインに利用しているソーシャルアカウントで、メールアドレスの認証をお願いいたします。
                        </p>
                      </div>
                    )}

                    <div>
                      <EmailSettingsForm />
                    </div>
                  </ContentArea>
                </section>

                <div className={styles['backToHomeButtonWrapper']}>
                  <LinkButtonPrimaryLarge to="/" label="ホームに戻る" />
                </div>

                <div>
                  <ContentArea>
                    <InlineBlockWrapper
                      className={styles['logoutButtonWrapper']}
                    >
                      <ButtonDefault onClick={onLoguoutButtonClick}>
                        ログアウト
                      </ButtonDefault>
                    </InlineBlockWrapper>
                  </ContentArea>
                </div>
              </ContainerDefault>
            </section>
          </main>
        </ContentWrapper>
      </PageDefault>

      <GlobalNavigation />

      <SiteFooter />
    </Fragment>
  );
};

export default () => (
  <Location>{({ location }) => <MainPage location={location} />}</Location>
);
