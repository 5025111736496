// extracted by mini-css-extract-plugin
export var backToHomeButtonWrapper = "index-module--back-to-home-button-wrapper--U17xS";
export var contentAreaHeading = "index-module--content-area-heading--K3pmx";
export var contentAreaHeadingWrapper = "index-module--content-area-heading-wrapper--S0Fcx";
export var emailSettingsSection = "index-module--email-settings-section--9uysk";
export var headingWrapper = "index-module--heading-wrapper--feVnp";
export var logoutButtonWrapper = "index-module--logout-button-wrapper--9a9aE";
export var main = "index-module--main--cJVWe";
export var message = "index-module--message--ptlTM";
export var messageWrapperInfo = "index-module--message-wrapper-info--BusdU";
export var profileSection = "index-module--profile-section--11dM6";